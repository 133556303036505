<template>
  <svg
    :width="size.width"
    :height="size.height"
    viewBox="0 0 188 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2974_48411"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :width="size.width"
      :height="size.height"
    >
      <circle
        cx="94"
        cy="94"
        r="94"
        fill="#0A0A0A"
      />
    </mask>
    <g mask="url(#mask0_2974_48411)">
      <circle
        cx="94"
        cy="94"
        r="94"
        fill="url(#paint0_linear_2974_48411)"
      />
      <path
        d="M115.449 54.3367C113.934 60.4573 109.364 65.7677 105.82 69.1116C103.321 71.4969 101.326 72.8894 101.326 72.8894L94.0778 96.4117L93.3139 98.8928C93.3139 98.8928 94.4353 102.686 95.5068 108.94C97.0061 117.593 98.417 130.956 96.6541 145.482L96.6481 145.544C95.401 155.82 92.5761 166.675 87.0668 176.844C77.9736 193.637 61.1874 198.618 45.9155 196.836C36.4965 195.743 27.6534 192.088 21.5418 187.058C16.9317 183.259 13.8828 178.684 13.3164 173.841C10.7688 152.126 26.9021 154.853 48.2843 123.853C57.1698 110.973 58.861 101.541 57.998 93.7671C56.7988 82.8279 50.5461 75.1943 52.1761 65.9338C54.964 50.1 80.3804 32.5809 96.8697 33.8296C109.613 34.789 115.3 40.2498 116.033 47.5214C116.256 49.6612 116.035 51.9543 115.449 54.3367Z"
        fill="#FD6B51"
      />
      <path
        d="M96.5117 145.405L96.5057 145.467C95.2594 155.755 92.4467 166.623 86.9702 176.806C77.9312 193.624 61.2665 198.623 46.1102 196.853C34.7766 189.783 32.3614 172.656 47.4925 156.789C72.6145 130.461 63.9045 90.5315 70.0074 79.9893C76.1015 69.4463 89.5766 70.5115 89.5766 70.5115L91.7345 81.5012C91.7345 81.5012 92.8291 87.2916 93.9951 96.2878C94.4782 99.9877 94.9716 104.223 95.4031 108.827C96.4338 119.78 97.1256 132.776 96.5117 145.405Z"
        fill="url(#paint1_linear_2974_48411)"
      />
      <path
        d="M115.591 54.4426C114.083 60.5846 109.549 65.9173 106.033 69.2762C105.468 66.9892 105.787 63.6798 105.787 63.6798C105.787 63.6798 111.848 52.3767 111.826 45.6409C111.821 42.3986 114.484 43.7712 116.179 47.6048C116.396 49.7512 116.175 52.0518 115.591 54.4426Z"
        fill="url(#paint2_linear_2974_48411)"
      />
      <path
        d="M23.9253 158.019C15.868 167.008 18.1891 178.776 21.5401 187.056C16.93 183.256 13.8811 178.682 13.3147 173.838C10.7671 152.124 26.9004 154.851 48.2826 123.85C57.1682 110.971 58.8593 101.538 57.9964 93.7649C59.1826 96.9766 59.9432 101.652 59.0693 108.235C56.9713 123.93 37.7868 142.556 23.9253 158.019Z"
        fill="url(#paint3_linear_2974_48411)"
      />
      <path
        d="M100.462 45.7403C100.462 45.7403 106.616 58.1257 87.2435 64.574C67.8701 71.0222 63.0656 76.7523 62.1341 96.4757C62.1341 96.4757 55.813 77.7883 67.6461 67.3281C79.4785 56.8669 99.75 55.1453 100.462 45.7403Z"
        fill="url(#paint4_linear_2974_48411)"
      />
      <path
        d="M81.1495 92.6145C81.1495 92.6145 88.4999 91.4838 95.6035 92.5277C102.707 93.5723 102.982 94.6833 103.219 95.6937C103.456 96.704 102.033 100.228 102.033 100.228H98.2841L93.5274 101.877C93.5274 101.877 84.9988 101.283 82.7227 98.1311C80.4473 94.9804 81.1495 92.6145 81.1495 92.6145Z"
        fill="#CDE9F9"
      />
      <path
        d="M111.959 104.711C111.959 104.711 107.878 100.716 105.341 97.7178C105.341 97.7178 104.707 101.54 102.488 100.904C100.268 100.268 96.7856 98.8646 94.6423 102.182C92.499 105.5 82.6872 101.214 82.6872 101.214L76.2515 101.458L88.2176 209.193L119.654 204.445C119.654 204.445 121.113 114.703 111.959 104.711Z"
        fill="white"
      />
      <path
        d="M100.428 106.62C92.4559 107.09 86.5068 100.743 84.8607 97.4942L86.0011 85.6444C85.9577 85.6218 85.9045 85.6076 85.8611 85.585C85.8074 83.9657 84.5203 81.2463 81.233 79.9027C76.1142 77.8051 77.7156 71.1998 79.2864 71.351C80.7939 71.4961 83.7294 75.2637 83.965 75.5615C83.8249 75.2179 82.1877 71.0249 84.8809 66.7076C87.6836 62.199 96.4743 65.4296 104.624 56.859C106.328 55.0603 107.563 53.3082 108.449 51.6693C109.361 63.8725 109.655 86.4718 99.4503 87.9935L98.497 97.8986C98.497 97.8986 108.409 106.15 100.428 106.62Z"
        fill="#FDE0D9"
      />
      <path
        d="M99.5919 87.998L99.0661 93.4614C98.4305 93.8973 97.8588 94.1236 97.3798 94.0212C94.857 93.4783 91.7987 86.2539 91.7987 86.2539C95.0371 87.6159 97.5966 88.0498 99.5919 87.998Z"
        fill="#ECBFB9"
      />
      <path
        d="M103.246 195.241C103.246 195.241 96.0862 175.228 97.9981 155.447C99.91 135.666 101.654 109.983 99.1172 107.128C99.1172 107.128 101.062 109.041 100.961 110.303C100.961 110.303 104.002 106.687 103.246 103.797C102.49 100.907 98.2449 98.6831 93.5276 101.875C88.8104 105.066 77.756 100.907 77.756 100.907C77.756 100.907 94.4474 157.546 93.294 171.657C92.1406 185.768 92.299 195.242 92.299 195.242H103.246V195.241Z"
        fill="#EAEAEA"
      />
      <path
        d="M114.482 243.906V331.386C114.482 331.386 108.4 337.015 102.116 333.405C93.9257 328.699 93.9257 328.699 93.9257 328.699L93.2914 199.685C93.2914 199.685 113.45 183.017 118.551 201.191C120.513 208.174 115.431 225.293 114.482 243.906Z"
        fill="#222457"
      />
      <path
        d="M188.235 105.546C188.235 105.546 189.171 100.71 190.957 99.3911C192.744 98.072 194.188 95.8783 194.615 94.2248C195.042 92.5714 196.217 93.8051 195.883 96.4886C195.55 99.1713 195.125 99.5115 195.125 99.5115C195.125 99.5115 199.548 99.1713 203.291 96.2032C207.033 93.2357 203.716 98.9509 201.504 100.49C199.293 102.029 199.293 102.029 199.293 102.029C199.293 102.029 204.289 99.6101 206.639 97.8515C208.99 96.0929 209.17 96.6981 208.82 98.2778C208.469 99.8575 203.353 103.675 202.165 104.562C202.165 104.562 206.542 103.694 208.319 102.476C210.096 101.258 209.648 104.336 206.639 105.984C203.631 107.633 202.015 108.182 202.015 108.182C202.015 108.182 204.056 108.233 206.268 107.603C208.479 106.974 209.5 108.402 207.033 109.831C204.567 111.259 197.912 113.593 196.551 114.582C195.191 115.571 194.274 117.745 190.021 115.326C185.769 112.908 188.235 105.546 188.235 105.546Z"
        fill="#FDE0D9"
      />
      <path
        d="M188.846 104.233C188.846 104.233 148.435 118.158 139.764 116.268C132.703 114.728 114.318 103.397 107.734 99.2415C106.961 98.6721 106.165 98.1626 105.342 97.718C105.342 97.718 114.121 115.245 114.537 150.53C114.953 185.815 109.939 267.937 109.939 267.937C109.939 267.937 123.099 268.918 125.953 259.667C127.97 253.13 134.261 184.375 125.469 137.946C128.305 138.249 131.865 138.542 136.366 138.802C158.511 140.075 193.284 116.445 193.284 116.445C193.79 105.817 188.846 104.233 188.846 104.233Z"
        fill="#E7F6FD"
      />
      <path
        d="M180.23 124.354C168.083 131.092 149.877 139.582 136.365 138.808C131.868 138.545 128.306 138.253 125.473 137.947C132.116 173.038 130.145 220.889 127.911 244.986C128.167 236.314 128.437 224.043 128.692 206.698C129.583 146.174 121.684 119.689 121.4 118.733C121.736 119.259 127.357 127.807 147.424 129.88C158.615 131.034 171.105 127.683 180.23 124.354Z"
        fill="url(#paint5_linear_2974_48411)"
      />
      <g opacity="0.09">
        <path
          opacity="0.09"
          d="M193.479 116.587C193.479 116.587 188.194 120.179 180.426 124.493C168.279 131.231 150.073 139.721 136.561 138.947C132.064 138.684 128.502 138.392 125.669 138.086C132.312 173.177 130.341 221.028 128.108 245.125C127.392 252.907 126.64 258.214 126.151 259.813C123.297 269.062 110.135 268.076 110.135 268.076C110.135 268.076 115.15 185.959 114.734 150.671C114.318 115.39 105.536 97.8628 105.536 97.8628C106.361 98.3081 107.157 98.8118 107.93 99.3812C114.515 103.542 132.904 114.872 139.963 116.412C148.628 118.303 189.04 104.374 189.04 104.374C189.04 104.374 193.982 105.958 193.479 116.587Z"
          fill="url(#paint6_linear_2974_48411)"
        />
      </g>
      <path
        d="M115.193 180.503L120.977 135.719L148.275 113.027L145.798 155.062L115.193 180.503Z"
        fill="#AECFDC"
      />
      <path
        d="M115.193 180.503L120.977 135.719L148.275 113.027L145.798 155.062L115.193 180.503Z"
        fill="url(#paint7_linear_2974_48411)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M115.193 180.503L145.798 155.062L148.275 113.027L120.977 135.719L115.193 180.503ZM117.071 177.089L144.412 154.362L146.659 116.224L122.319 136.457L117.071 177.089Z"
        fill="#3C7FBE"
      />
      <path
        d="M134.059 146.241L131.874 148.135C132.423 148.768 133.221 149.132 134.059 149.132C135.645 149.132 136.95 147.827 136.95 146.241C136.95 144.654 135.645 143.349 134.059 143.349C132.472 143.349 131.167 144.654 131.167 146.241L134.059 146.241Z"
        fill="#1E2A67"
      />
      <path
        d="M111.514 170.634C111.514 170.634 114.803 169.526 116.001 166.809C117.199 164.091 120.237 161.373 122.197 161.272C124.157 161.172 125.554 160.97 126.571 160.669C127.588 160.366 126.902 162.38 122.393 164.393C122.393 164.393 126.306 164.695 129.985 163.689C133.665 162.682 133.86 161.374 134.546 161.978C135.233 162.581 132.331 165.505 130.626 166.306C130.626 166.306 132.978 165.903 133.959 165.098C134.939 164.292 135.169 166.238 131.313 171.556C127.456 176.874 119.16 182.813 111.514 179.592C103.87 176.372 111.514 170.634 111.514 170.634Z"
        fill="#FDE0D9"
      />
      <path
        d="M76.2528 97.718C76.2528 97.718 48.8524 109.079 48.8984 118.185C48.9444 127.291 51.6556 207.312 50.4839 230.756C49.3123 254.201 46.8245 274.834 46.8245 274.834C46.8245 274.834 77.2858 283.713 94.6437 274.834C94.6437 274.834 98.0499 172.165 92.0245 142.321C85.9991 112.477 81.7271 102.302 76.2528 97.718Z"
        fill="#E7F6FD"
      />
      <g opacity="0.47">
        <path
          opacity="0.47"
          d="M94.6449 274.837C88.3304 278.064 80.2858 278.947 72.5989 278.758C70.19 278.699 67.8102 278.531 65.5399 278.305C57.8311 277.531 51.2757 275.998 48.3922 275.261C47.3848 274.998 46.8228 274.837 46.8228 274.837C46.8228 274.837 49.312 254.208 50.48 230.76C50.9837 220.715 50.772 200.276 50.3632 179.478C49.823 151.753 48.9251 123.392 48.8959 118.187C48.8886 116.603 49.7135 114.953 51.0786 113.304C51.8451 112.377 52.7722 111.457 53.8307 110.552C61.7074 103.755 76.2489 97.7255 76.2489 97.7255C81.7239 102.31 86.0017 112.486 92.0242 142.328C98.0467 172.171 94.6449 274.837 94.6449 274.837Z"
          fill="url(#paint8_linear_2974_48411)"
        />
      </g>
      <path
        d="M81.8761 92.5277C81.8761 92.5277 83.931 98.5407 89.9623 100.115C95.9935 101.69 96.7644 100.299 96.7644 100.299C96.7644 100.299 95.2161 103.776 95.6015 107.812C95.987 111.849 95.9869 111.849 95.9869 111.849C95.9869 111.849 79.2554 104.097 77.7538 100.91C76.2515 97.7238 76.2515 97.7238 76.2515 97.7238C76.2515 97.7238 77.956 96.4588 79.1371 95.0411C80.0927 93.8928 80.3504 92.5379 81.8761 92.5277Z"
        fill="#E7F6FD"
      />
      <path
        d="M102.805 94.7235C102.805 94.7235 102.647 97.5771 100.903 98.8458C99.1588 100.115 96.7651 100.298 96.7651 100.298C96.7651 100.298 101.955 102.002 101.904 105.101C101.854 108.201 101.854 108.201 101.854 108.201C101.854 108.201 106.53 102.064 105.344 97.7304C105.175 97.1113 104.648 96.6909 104.208 96.1689C103.799 95.6827 103.499 95.087 102.805 94.7235Z"
        fill="#E7F6FD"
      />
      <path
        d="M58.0499 228.422C54.2758 252.738 49.8447 266.995 48.392 275.258C47.3846 274.996 46.8225 274.835 46.8225 274.835C46.8225 274.835 49.3118 254.205 50.4798 230.758C50.9835 220.713 50.7718 200.273 50.363 179.475C55.0423 149.575 60.8969 123.82 57.9769 120.937C55.6701 118.659 53.0713 115.798 51.0784 113.301C51.8449 112.374 52.772 111.454 53.8305 110.549C55.8088 111.943 58.3492 113.87 61.5393 116.433C61.5393 116.433 71.8468 125.747 75.5698 148.545C79.2928 171.343 63.1233 195.754 58.0499 228.422Z"
        fill="url(#paint9_linear_2974_48411)"
      />
      <path
        d="M51.752 113.43C51.752 113.43 66.0213 118.186 68.2412 133.407C70.4611 148.628 64.6635 156.238 70.7334 159.409C76.8033 162.581 115.095 167.936 115.095 167.936C115.095 167.936 117.955 176.74 113.393 180.759C113.393 180.759 62.045 184.668 51.9834 180.759C41.9219 176.85 41.0167 165.414 41.351 159.288C41.6853 153.161 41.605 114.077 51.752 113.43Z"
        fill="#E7F6FD"
      />
      <g opacity="0.47">
        <path
          opacity="0.47"
          d="M51.752 113.43C51.752 113.43 66.0213 118.186 68.2412 133.407C70.4611 148.628 64.6635 156.238 70.7334 159.409C76.8033 162.581 115.095 167.936 115.095 167.936C115.095 167.936 117.955 176.74 113.393 180.759C113.393 180.759 62.045 184.668 51.9834 180.759C41.9219 176.85 41.0167 165.414 41.351 159.288C41.6853 153.161 41.605 114.077 51.752 113.43Z"
          fill="url(#paint10_linear_2974_48411)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2974_48411"
        x1="80.8877"
        :y1="size.height"
        x2="124.822"
        y2="13.2826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#88B5FE" />
        <stop
          offset="0.9984"
          stop-color="#88B5FE"
          stop-opacity="0.15"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2974_48411"
        x1="41.4361"
        y1="131.148"
        x2="97.3658"
        y2="136.531"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2974_48411"
        x1="106.5"
        y1="56.2681"
        x2="115.589"
        y2="57.1428"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2974_48411"
        x1="15.9577"
        y1="138.12"
        x2="55.8541"
        y2="141.96"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2974_48411"
        x1="61.8326"
        y1="69.2354"
        x2="99.2717"
        y2="72.8386"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#21409A"
          stop-opacity="0.43"
        />
        <stop
          offset="1"
          stop-color="#21409A"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2974_48411"
        x1="121.4"
        y1="181.86"
        x2="180.23"
        y2="181.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0607"
          stop-color="#28A8E0"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2974_48411"
        x1="105.536"
        y1="182.985"
        x2="193.515"
        y2="182.985"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0607"
          stop-color="#28A8E0"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2974_48411"
        x1="110.044"
        y1="150.485"
        x2="153.424"
        y2="143.046"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#39AEE1" />
        <stop
          offset="1"
          stop-color="#2A2E6C"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2974_48411"
        x1="46.8228"
        :y1="size.height"
        x2="95.602"
        :y2="size.height"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0607"
          stop-color="#28A8E0"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2974_48411"
        x1="46.8225"
        y1="192.904"
        x2="76.1209"
        y2="192.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0607"
          stop-color="#28A8E0"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2974_48411"
        x1="41.2963"
        y1="147.963"
        x2="115.981"
        y2="147.963"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.0607"
          stop-color="#28A8E0"
        />
        <stop
          offset="0.9984"
          stop-color="#29ABE2"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ["size"]
}
</script>
